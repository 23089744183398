<template>
    <div class="margin-bottom-20">
        <div class="title-container">
            <div
                class="content-viewtitle"
                :class="{ active: isToggle }"
                @click="hasClick ? (isToggle = !isToggle) : ''"
            >
                保單內容
                <div v-if="!hideToggleArrow" class="toggle-menu-icon" />
            </div>
            <div
                v-if="isProperty && $hasPermission('editInfo')"
                class="edit-data-wrapper"
                @click="
                    showEditModal(),
                        $setGaEvent('editProperty', 'click-Button', {
                            params: 'detail'
                        })
                "
            >
                <div class="edit-data-icon" />
            </div>
        </div>
        <Transition name="dropdown" mode="out-in">
            <div class="show-wrapper" v-show="isToggle">
                <div
                    class="ins-content-wrapper"
                    :class="{ preload: isLoading }"
                >
                    <div class="customer">
                        <div class="customer-title">客戶資料</div>
                        <div
                            :class="[
                                'customer-list',
                                { toggled: contentList.isCustomerToggle }
                            ]"
                        >
                            <div class="list">
                                <div
                                    v-for="(column, index) in Object.keys(
                                        setContentColumns?.customer
                                    ).slice(0, 4)"
                                    :key="index"
                                    :class="`list-item ${column}`"
                                    @click="contentToggle = !contentToggle"
                                >
                                    <div class="label">
                                        {{ contentColumns[column] }}
                                    </div>
                                    <div
                                        class="value text-line"
                                        :class="{
                                            'no-data': showData[column] === '-'
                                        }"
                                        @click="
                                            goCustomer(column, showData[column])
                                        "
                                    >
                                        <span
                                            v-if="
                                                ['payer', 'insured'].includes(
                                                    column
                                                )
                                            "
                                            v-show="!isLoading"
                                            :class="
                                                riskClassName(column, showData)
                                            "
                                        />
                                        <span class="">
                                            {{
                                                !isLoading
                                                    ? showData[column]
                                                    : ''
                                            }}
                                        </span>
                                        <div
                                            v-if="
                                                [
                                                    'insured',
                                                    'applicationDate'
                                                ].includes(column)
                                            "
                                            class="dash-line"
                                        ></div>
                                    </div>
                                </div>
                                <template v-if="contentList.isCustomerToggle">
                                    <div
                                        v-for="(column, index) in Object.keys(
                                            setContentColumns?.customer
                                        ).slice(4)"
                                        :key="index"
                                        :class="`toggle-item ${column}`"
                                        class="common-layout_toggle"
                                    >
                                        <div class="label">
                                            {{
                                                !isLoading
                                                    ? contentColumns[column]
                                                    : ''
                                            }}
                                        </div>
                                        <div class="value text-line">
                                            {{
                                                !isLoading
                                                    ? showData[column]
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="icon"
                                @click="
                                    contentList.isCustomerToggle = !contentList.isCustomerToggle
                                "
                            >
                                <div
                                    :class="[
                                        {
                                            'icon-title': !contentList.isCustomerToggle
                                        },
                                        {
                                            'icon-title_expanded':
                                                contentList.isCustomerToggle
                                        }
                                    ]"
                                >
                                    {{
                                        !contentList.isCustomerToggle
                                            ? '更多'
                                            : '收合'
                                    }}
                                </div>
                                <div
                                    :class="[
                                        {
                                            'toggle-content-icon': !contentList.isCustomerToggle
                                        },
                                        {
                                            'toggle-content-icon_expanded':
                                                contentList.isCustomerToggle
                                        }
                                    ]"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="company">
                        <div class="company-title">保險公司</div>
                        <div class="company-list">
                            <div class="list">
                                <div
                                    v-for="(column, index) in Object.keys(
                                        setContentColumns?.company
                                    )"
                                    :key="index"
                                    :class="`list-item ${column}`"
                                    @click="contentToggle = !contentToggle"
                                >
                                    <div class="label">
                                        {{ contentColumns[column] }}
                                    </div>
                                    <div class="value text-line">
                                        {{ !isLoading ? showData[column] : '' }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div
                                class="toggle-menu-icon"
                                :class="{
                                    'toggle-menu-icon_active': isContentToggle
                                }"
                                @click="test"
                            ></div> -->
                        </div>
                    </div>
                    <div class="date">
                        <div class="date-title">日期類別</div>
                        <div
                            :class="[
                                'date-list',
                                { toggled: contentList.isDateToggle }
                            ]"
                        >
                            <div class="list">
                                <div
                                    v-for="(column, index) in Object.keys(
                                        setContentColumns?.date
                                    ).slice(0, 4)"
                                    :key="index"
                                    :class="`list-item ${column}`"
                                    @click="contentToggle = !contentToggle"
                                >
                                    <div class="label">
                                        {{ contentColumns[column] }}
                                    </div>
                                    <div class="value text-line">
                                        {{ !isLoading ? showData[column] : '' }}
                                    </div>
                                </div>
                                <template v-if="contentList.isDateToggle">
                                    <div
                                        v-for="(column, index) in Object.keys(
                                            setContentColumns?.date
                                        ).slice(4)"
                                        :key="index"
                                        :class="`toggle-item ${column}`"
                                        class="common-layout_toggle"
                                    >
                                        <div class="label">
                                            {{
                                                !isLoading
                                                    ? contentColumns[column]
                                                    : ''
                                            }}
                                        </div>
                                        <div class="value text-line">
                                            {{
                                                !isLoading
                                                    ? showData[column]
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="icon"
                                @click="
                                    contentList.isDateToggle = !contentList.isDateToggle
                                "
                            >
                                <div
                                    :class="[
                                        {
                                            'icon-title': !contentList.isDateToggle
                                        },
                                        {
                                            'icon-title_expanded':
                                                contentList.isDateToggle
                                        }
                                    ]"
                                >
                                    {{
                                        !contentList.isDateToggle
                                            ? '更多'
                                            : '收合'
                                    }}
                                </div>
                                <div
                                    :class="[
                                        {
                                            'toggle-content-icon': !contentList.isDateToggle
                                        },
                                        {
                                            'toggle-content-icon_expanded':
                                                contentList.isDateToggle
                                        }
                                    ]"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div class="record">
                        <div class="record-title">收款紀錄</div>
                        <div
                            :class="[
                                'record-list',
                                { toggled: contentList.isRecordToggle }
                            ]"
                        >
                            <div class="list">
                                <div
                                    v-for="(column, index) in Object.keys(
                                        setContentColumns?.record
                                    ).slice(0, 4)"
                                    :key="index"
                                    :class="`list-item ${column}`"
                                    @click="contentToggle = !contentToggle"
                                >
                                    <div class="label">
                                        {{ contentColumns[column] }}
                                    </div>
                                    <div class="value text-line">
                                        {{ !isLoading ? showData[column] : '' }}
                                    </div>
                                </div>
                                <template v-if="contentList.isRecordToggle">
                                    <div
                                        v-for="(column, index) in Object.keys(
                                            setContentColumns.record
                                        ).slice(4)"
                                        :key="index"
                                        :class="`toggle-item ${column}`"
                                    >
                                        <div class="label">
                                            {{
                                                !isLoading
                                                    ? contentColumns[column]
                                                    : ''
                                            }}
                                        </div>
                                        <div class="value text-line">
                                            {{
                                                !isLoading &&
                                                showData?.initialPremium
                                                    ? showData?.initialPremium[
                                                          column
                                                      ]
                                                    : '-'
                                            }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="icon"
                                v-if="showData.initialPremium !== null"
                                @click="
                                    contentList.isRecordToggle = !contentList.isRecordToggle
                                "
                            >
                                <div
                                    :class="[
                                        {
                                            'icon-title': !contentList.isRecordToggle
                                        },
                                        {
                                            'icon-title_expanded':
                                                contentList.isRecordToggle
                                        }
                                    ]"
                                >
                                    {{
                                        !contentList.isRecordToggle
                                            ? '更多'
                                            : '收合'
                                    }}
                                </div>
                                <div
                                    :class="[
                                        {
                                            'toggle-content-icon': !contentList.isRecordToggle
                                        },
                                        {
                                            'toggle-content-icon_expanded':
                                                contentList.isRecordToggle
                                        }
                                    ]"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="other">
                        <div class="other-title">其他</div>
                        <div
                            :class="[
                                'list-wrapper',
                                { toggled: contentList.isOtherToggle }
                            ]"
                        >
                            <div class="other-list">
                                <div class="list">
                                    <div
                                        v-for="(column, index) in Object.keys(
                                            setContentColumns.others
                                        ).slice(0, 1)"
                                        :key="index"
                                        :class="`list-item ${column}`"
                                        @click="contentToggle = !contentToggle"
                                    >
                                        <div class="label">
                                            {{ contentColumns[column] }}
                                        </div>
                                        <div class="value text-line">
                                            {{
                                                !isLoading
                                                    ? showData[column]
                                                    : ''
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="list">
                                    <template v-if="contentList.isOtherToggle">
                                        <div
                                            v-for="(
                                                column, index
                                            ) in Object.keys(
                                                setContentColumns.others
                                            ).slice(1)"
                                            :key="index"
                                            :class="`toggle-item ${column}`"
                                            class="common-layout_toggle"
                                        >
                                            <div class="label">
                                                {{ contentColumns[column] }}
                                            </div>
                                            <div class="value text-line">
                                                {{
                                                    !isLoading
                                                        ? showData[column]
                                                        : ''
                                                }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div
                                class="icon"
                                @click="
                                    contentList.isOtherToggle = !contentList.isOtherToggle
                                "
                            >
                                <div
                                    :class="[
                                        {
                                            'icon-title': !contentList.isOtherToggle
                                        },
                                        {
                                            'icon-title_expanded':
                                                contentList.isOtherToggle
                                        }
                                    ]"
                                >
                                    {{
                                        !contentList.isOtherToggle
                                            ? '更多'
                                            : '收合'
                                    }}
                                </div>
                                <div
                                    :class="[
                                        {
                                            'toggle-content-icon': !contentList.isOtherToggle
                                        },
                                        {
                                            'toggle-content-icon_expanded':
                                                contentList.isOtherToggle
                                        }
                                    ]"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import _ from 'lodash'
import { insuranceContentColumns } from '@/assets/javascripts/tia/setting'
import { Transition } from 'vue'

export default {
    name: 'InsuranceContent',
    emits: ['update:modelValue'],
    components: { Transition },
    props: {
        modelValue: {
            type: Object,
            default: function () {
                return { isShow: false }
            }
        },
        dataList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isDefaultClose: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        isShowModal: {
            type: Boolean,
            default: function () {
                return null
            }
        }
    },
    methods: {
        goCustomer: function (col, val) {
            if (!val || val === '-') return
            /*GA*/
            if (col === 'insured' || col === 'payer') {
                this.$setGaEvent('goSearchCustomer', 'click-Link', {
                    params: this.getPageParams()
                })
                this.$store.commit('set', { isShowModalForGa: false })
                this.$crossPageFilter('TiaCustomer', {
                    customerCode: this.showData[`${col}Code`],
                    customerName: this.showData[col]
                })
            }
        },
        getPageParams: function () {
            let params
            if (this.$route.params) {
                if (this.$route.params.mode && !this.$route.params.id)
                    params = this.$route.params.mode

                if (
                    this.$route.name === 'TiaInsurance' &&
                    this.$route.params.id
                )
                    params = 'detail'
            } else params = ''
            return params
        },
        riskClassName: function (key, row) {
            if (key === 'insured') {
                const risk = row.insuredRiskText
                if (risk == '一般風險' || risk == '行銷風險')
                    return `normal-risk-icon`
                else if (risk == '高風險') return `heigh-risk-icon`
                else return 'no-risk'
            }
            if (key == 'payer') {
                const risk = row.payerRiskText
                if (risk == '一般風險' || risk == '行銷風險')
                    return `normal-risk-icon`
                else if (risk == '高風險') return `heigh-risk-icon`
                else return ''
            }
        },
        setToggleState: function () {
            if (
                !this.$isMobile() &&
                this.$route.name === 'TiaInsuranceDetail'
            ) {
                this.isToggle = true
                this.hasClick = false
            } else this.hasClick = true
        },
        showEditModal: function () {
            if (!this.$hasPermission('editInfo')) return
            this.$emit('update:modelValue', { isShow: true, key: 'content' })
        },
        test: function () {
            this.isContentToggle = !this.isContentToggle
        }
    },
    computed: {
        showData: function () {
            const row = _.cloneDeep(this.dataList)
            row.isLoanPremium =
                row.isLoanPremium === true
                    ? '是'
                    : row.isLoanPremium === false
                    ? '否'
                    : '-'
            if (row.insuredAge !== null && row.insuredAge !== undefined) {
                row.insuredAge = `${row?.insuredAge}歲`
            }
            Object.keys(this.contentColumns).forEach((col) => {
                row[col] = row[col] ? row[col] : '-'
            })

            if (row.initialPremium) {
                Object.keys(this.contentColumns).forEach((col) => {
                    row.initialPremium[col] = row.initialPremium[col]
                        ? row.initialPremium[col]
                        : '-'
                })
                if (row.initialPremium.amount !== '-') {
                    row.initialPremium.amount = this.$numberWithComma(
                        row.initialPremium.amount
                    )
                }
                if (row.initialPremium.payAccount !== '-') {
                    row.initialPremium.payAccount = row.initialPremium.payAccount.replace(
                        /(\d{4})(\d{4})(\d{4})(\d{4})/,
                        '$1-$2-$3-$4'
                    )
                }
                if (row.initialPremium.checkNo !== '-') {
                    row.initialPremium.checkNo = row.initialPremium.checkNo.replace(
                        /(\d{9})/,
                        '$1-2'
                    )
                }
                if (row.initialPremium.creditCardValidThru !== '-') {
                    row.initialPremium.creditCardValidThru = row.initialPremium.creditCardValidThru.replace(
                        /(\d{2})(\d{2})/,
                        '$1/$2'
                    )
                }
            }
            return row
        },
        hideToggleArrow: function () {
            return (
                !this.$isMobile() && this.$route.name === 'TiaInsuranceDetail'
            )
        },
        isProperty: function () {
            const jobType = this.dataList?.jobType
            const jobTypeFilter = [100, 130]
            return jobTypeFilter.includes(jobType)
        },
        setContentColumns: function () {
            const columns = _.cloneDeep(this.contentColumns)
            if (!this.isProperty) {
                delete columns.vehicleNumber
                delete columns.propertyDueDate
            }
            //各欄位名稱可參考contentColumns內容
            columns.customer = {
                insured: columns.insured,
                payer: columns.payer,
                introName: columns.introName,
                vehicleNumber: columns.vehicleNumber,
                //收合區塊
                introId: columns.introID,
                customerSourceText: columns.customerSourceText,
                insuredAge: columns.insuredAge
            }
            columns.date = {
                effectiveDate: columns.effectiveDate,
                receiveDate: columns.receiveDate,
                propertyDueDate: columns.propertyDueDate,
                applicationDate: columns.applicationDate,
                //收合區塊
                rDate: columns.rDate,
                bDate: columns.bDate,
                receiptDueDate: columns.receiptDueDate,
                policyIssueDate: columns.policyIssueDate
            }
            columns.company = {
                supplier: columns.supplier,
                insNo: columns.insNo
            }
            columns.record = {
                payWayFirstText: columns.payWayFirstText,
                payWayRenewalText: columns.payWayRenewalText,
                currencyText: columns.currencyText,
                payType: columns.payType,
                //收合區域
                amountType: columns?.initialPremium?.amountType,
                payWayText: columns?.initialPremium?.payWayText,
                amount: columns?.initialPremium?.amount,
                payDate: columns?.initialPremium?.payDate,
                payAccount: columns?.initialPremium?.payAccount,
                payBank: columns?.initialPremium?.payBank,
                creditCardValidThru:
                    columns?.initialPremium?.creditCardValidThru,
                checkNo: columns?.initialPremium?.checkNo,
                checkDate: columns?.initialPremium?.checkDate,
                checkIssuer: columns?.initialPremium?.checkIssuer,
                payMan: columns?.initialPremium?.payMan,
                modifiedDate: columns?.initialPremium?.modifiedDate,
                modifiedName: columns?.initialPremium?.modifiedName,
                remark: columns?.initialPremium?.remark
            }
            columns.others = {
                jobTypeText: columns.jobTypeText,
                //收合區域
                isLoanPremium: columns.isLoanPremium,
                licenseText: columns.licenseText,
                solicitorOri: columns.solicitorOri
            }
            return columns
        }
    },
    watch: {
        isShowModal: function () {
            if (this.isDefaultClose) this.isToggle = false
        },
        contentList: {
            handler() {
                const isDetail = this.$route.name === 'TiaInsuranceDetail'
                this.$setGaEvent(`contentCollapse`, 'click-icon', {
                    params: isDetail ? 'detail' : '',
                    isModal: isDetail ? false : true
                })
            },
            deep: true
        }
    },
    data() {
        return {
            contentColumns: insuranceContentColumns,
            isToggle: !this.isDefaultClose,
            hasClick: true,
            isContentToggle: false,
            contentList: {
                isCustomerToggle: false,
                isDateToggle: false,
                isRecordToggle: false,
                isCompanyToggle: false,
                isOtherToggle: false
            }
        }
    },
    mounted() {
        this.setToggleState()
        window.addEventListener('resize', this.setToggleState)
    },
    unmounted() {
        window.removeEventListener('resize', this.setToggleState)
    }
}
</script>

<style lang="scss" scoped>
%title-style {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
%title-gap {
    margin-bottom: 10px;
}
%content-gap {
    margin-bottom: 20px;
}
%content-layout {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, max-content));
    -ms-grid-columns: repeat(auto-fill, minmax(255px, max-content));
    gap: 0 20px;
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(auto-fill, 100%);
        margin-bottom: 5px;
    }
}
%content-layout_bg {
    background-color: $primary-grey;
    border-radius: 3px;
    @media screen and (max-width: 576px) {
        background-color: transparent;
    }
}
%item-label {
    width: 100px;
    min-width: 100px;
    margin-right: 10px;
    color: $placeholder-black;
    font-size: 14px;
    @media screen and(max-width:576px) {
        width: 100px;
    }
}
%item-layout {
    display: flex;
    box-sizing: border-box;
    margin-bottom: 10px;
    @media screen and(max-width:576px) {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 5px;
    }
}
%item-value {
    max-width: minmax(110px, max-content);
    display: flex;
    font-size: 14px;
    color: $sixth-black;
    word-break: break-all;
    @media screen and(max-width:320px) {
        font-size: 12px;
    }
}
:deep(.toggle-content-icon) {
    background-size: 6.26px 10.54px;
    height: 20px;
    width: 10.54px;
    cursor: pointer;
    align-self: baseline;
    @media screen and (max-width: 576px) {
        transform: rotate(90deg);
    }
    &_expanded {
        background-size: 10.54px 6.26px;
        height: 10.54px;
        width: 11px;
        cursor: pointer;
        @media screen and (max-width: 576px) {
            transform: rotate(180deg);
        }
    }
}

%icon-layout {
    display: flex;
    align-self: baseline;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media screen and (max-width: 576px) {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
%icon-title {
    color: $fourth-blue;
    white-space: nowrap;
    font-size: 12px;
    margin-right: 5px;
    @media screen and(max-width:576px) {
        height: 20px;
    }
}

// TODO 舊版css
.payer,
.insured {
    .value {
        color: $primary-blue;
        cursor: pointer;
        span:first-child {
            margin-right: 10px;
            margin-left: 0;
            @media screen and(max-width:576px) {
                margin-right: 5px;
            }
        }
        span:nth-child(2) {
            text-decoration: underline;
            color: $fourth-blue;
            @media screen and (max-width: 576px) {
                text-align: end;
            }
        }
        &.no-data {
            color: $eighth-black;
            cursor: default;
            span:first-child {
                margin-right: 0px;
                @media screen and (max-width: 576px) {
                    margin-right: 0px;
                }
            }
            span:nth-child(2) {
                color: $eighth-black;
                text-decoration: none;
            }
        }
    }
    .dash-line {
        display: none;
    }
    .normal-risk-icon {
        width: 50px;
        height: 20px;
        &::after {
            content: '一般風險';
            display: flex;
            padding: 0 10px 0 10px;
            border-radius: 20px;
            height: 28px;
            width: max-content;
            color: $primary-white;
            background-color: $primary-blue;
            font-size: 20px;
            align-items: center;
            transform: scale(0.5) translate(-50%, -20%);
        }
    }
    .heigh-risk-icon {
        width: 40px;
        height: 20px;
        &::after {
            content: '高風險';
            display: flex;
            padding: 0 10px 0 10px;
            border-radius: 20px;
            height: 28px;
            width: max-content;
            color: $primary-white;
            background-color: $primary-red;
            font-size: 20px;
            align-items: center;
            transform: scale(0.5) translate(-50%, -20%);
        }
    }
    .no-risk {
        display: none;
    }
}

//----------<區域設定>----------//
.customer {
    @extend %content-gap;
    @media screen and(max-width:576px) {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px dashed $ninth-grey;
    }
    .toggled {
        @extend %content-layout_bg;
    }
    &-title {
        @extend %title-style;
        @extend %title-gap;
    }
    &-list {
        display: flex;
        align-items: center;
        padding: 5px 5px 0 5px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            padding: 0;
        }

        .list {
            @extend %content-layout;

            &-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
            .toggle-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
        }
        .icon {
            @extend %icon-layout;

            &-title {
                @extend %icon-title;
                &_expanded {
                    @extend %icon-title;
                    color: #aaaa;
                }
            }
        }
    }
}
.date {
    @extend %content-gap;
    @media screen and(max-width:576px) {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px dashed $ninth-grey;
    }
    .toggled {
        @extend %content-layout_bg;
    }
    &-title {
        @extend %title-style;
        @extend %title-gap;
    }
    &-list {
        display: flex;
        align-items: center;
        padding: 5px 5px 0 5px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            padding: 0;
        }

        .list {
            @extend %content-layout;

            &-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
            .toggle-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
        }
        .icon {
            @extend %icon-layout;

            &-title {
                @extend %icon-title;
                &_expanded {
                    @extend %icon-title;
                    color: #aaaa;
                }
            }
        }
    }
}
.company {
    @extend %content-gap;
    @media screen and(max-width:576px) {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px dashed $ninth-grey;
    }
    &-title {
        @extend %title-style;
        @extend %title-gap;
    }
    &-list {
        display: flex;
        align-items: center;
        padding: 5px 5px 0 5px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            padding: 0;
        }

        .list {
            @extend %content-layout;

            &-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
            //TODO 之後company內部有toggle內容可使用
            // .toggle-item {
            //     @extend %item-layout;
            //     .label {
            //         @extend %item-label;
            //     }
            //     .value {
            //         @extend %item-value;
            //     }
            // }
        }
        .icon {
            @extend %icon-layout;

            &-title {
                color: $fourth-blue;
                white-space: nowrap;
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
}
.record {
    @extend %content-gap;
    @media screen and(max-width:576px) {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px dashed $ninth-grey;
    }
    .toggled {
        @extend %content-layout_bg;
    }
    &-title {
        @extend %title-style;
        @extend %title-gap;
    }
    &-list {
        display: flex;
        align-items: center;
        padding: 5px 5px 0 5px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            padding: 0;
        }

        .list {
            @extend %content-layout;

            &-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
            .toggle-item {
                @extend %item-layout;
                .label {
                    @extend %item-label;
                }
                .value {
                    @extend %item-value;
                }
            }
        }
        .icon {
            @extend %icon-layout;

            &-title {
                @extend %icon-title;
                &_expanded {
                    @extend %icon-title;
                    color: #aaaa;
                }
            }
        }
    }
}
.other {
    @extend %content-gap;
    @media screen and(max-width:576px) {
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px dashed $ninth-grey;
    }
    .toggled {
        @extend %content-layout_bg;
    }
    &-title {
        @extend %title-style;
        @extend %title-gap;
    }
    .list-wrapper {
        display: flex;
        align-items: center;
        padding: 5px 5px 0 5px;
        @media screen and(max-width:576px) {
            flex-direction: column;
            padding: 0;
        }
        .other-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-basis: 100%;
            @media screen and(max-width:576px) {
                width: 100%;
            }

            .list {
                @extend %content-layout;
                @media screen and(max-width:576px) {
                    margin-bottom: 5px;
                }
                &-item {
                    @extend %item-layout;
                    .label {
                        @extend %item-label;
                    }
                    .value {
                        @extend %item-value;
                    }
                }
                .toggle-item {
                    @extend %item-layout;
                    @media screen and(max-width:576px) {
                        margin: 0;
                        margin-bottom: 5px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .label {
                        @extend %item-label;
                    }
                    .value {
                        @extend %item-value;
                    }
                }
            }
        }
        .icon {
            @extend %icon-layout;

            &-title {
                @extend %icon-title;
                &_expanded {
                    @extend %icon-title;
                    color: #aaaa;
                }
            }
        }
    }
}

.title-container {
    display: flex;
    align-items: flex-end;
    .content-viewtitle {
        display: flex;
        align-items: center;
        margin: 0;
        transition: margin 1s ease-in-out;
        color: $sixth-black;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;

        .toggle-menu-icon {
            height: 10px;
            width: 10px;
            margin-left: 15px;
            transition: transform 0.3s;
            transform: rotate(0deg);
            cursor: pointer;
        }

        &.active {
            .toggle-menu-icon {
                transform: rotate(90deg);
            }
        }
    }
    .edit-data-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-left: auto;
        width: 40px;
        height: 40px;
        cursor: pointer;

        .edit-data-icon {
            width: 24px;
            height: 24px;
        }
    }
}

.ins-content-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    background: $primary-white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 576px) {
        width: calc(100% - 8px);
        margin: 10px auto 0;
        padding: 15px;
    }
}
.show-wrapper {
    padding-bottom: 0;
    @media screen and (max-width: 576px) {
        padding-bottom: 4px;
    }
}

.dash-line {
    width: 100%;
    border-bottom: 1px $fourth-grey dashed;
}

.preload {
    .text-line {
        max-width: 50%;
        height: 20px;
        @media screen and (max-width: 375px) {
            max-width: 30%;
        }
    }
    .ins-content:nth-child(3n-1) {
        .text-line {
            width: 50%;
        }
    }
}
</style>
